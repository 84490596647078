@import url('https://fonts.googleapis.com/css?family=Slabo+27px');

body {
  font-family: 'Slabo 27px', serif;
  background-color: rgb(248, 249, 250);
}

.container {
  max-width: none !important;
}

.header {
  text-align: center;
}

.portfolioJumbotron h6 {
  text-align: center;
}

.portfolioImage {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  margin: 1em;
  margin-top: 0em;
  width: 100%;
}

.portfolioJumbotronContainer>.p-3>.p-5 {
  background-color: rgb(248, 249, 250);
  border: none;
}

.portfolioJumbotron {
  filter: drop-shadow(1em 1em 1em rgba(0, 0, 0, 0.15));

}

.portfolioJumbotronContainer>.jumbotronContainer>.jumbotron.container {
  padding: 2rem 3rem !important;
}

.footer {
  color: rgb(125, 125, 125);
  text-align: center;
}

.footer hr {
  margin: 1em 0;
  width: 100%;
  margin-bottom: 0.65em;
}

.footer h6 {
  line-height: 1;
  margin-bottom: 0;
}

.jumbotron {
  background-color: white;
  border: solid 1px black;
  width: 72%;
  margin: auto;
  padding-bottom: 1em !important;
  padding-top: 2em !important;
}

.portfolioJumbotron .jumbotron {
  width: 82%;
  border: solid 1px rgba(0, 0, 0, 0.25);
  padding: 2rem !important;
  padding-bottom: 1em !important;
  padding-top: 1em !important;
}

.portfolioJumbotron .jumbotron .container {
  font-size: 1.1em;
}

.portfolioJumbotron .jumbotronContainer {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.menuBar {
  background-color: rgb(24, 28, 32);
  height: 2.6em;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 1em;
}

h2 {
  border-bottom: 1px solid black;
  margin-top: 1em;
  margin-bottom: 0.75em;
}

.portfolioJumbotron .jumbotron h2 {
  border-bottom: none;
  margin-top: 0;
  margin-bottom: 0.1em;
}

table {
  width: 100%;
}

td li {
  width: 95%
}

td:nth-child(2) {
  text-align: right;
}

td h4 {
  margin-top: 0em;
  margin-bottom: 0em;
}

td h5 {
  margin-top: 0em;
  margin-bottom: 0em;
}

h5 {
  margin-top: 1em;
}


@media only screen and (max-width: 760px) {

  .portfolioJumbotronContainer>.jumbotronContainer>.jumbotron.container {
    padding: 0 !important;
  }

  .jumbotron {
    width: 100%;
  }
}